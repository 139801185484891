import Column from '@amzn/meridian/column';
import Button from '@amzn/meridian/button';
import Input from '@amzn/meridian/input';
import React, { useState } from "react"
import Row from '@amzn/meridian/row';
import Heading from '@amzn/meridian/heading';
import Alert from '@amzn/meridian/alert';
import Amplify, { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import Tooltip from "@amzn/meridian/tooltip";
import Loader from "@amzn/meridian/loader"


const Components = () => {
    const [customerId, setcustomerId] = useState();
    const [productId, setproductId] = useState();
    const [customerIdError, setcustomerIdError] = useState();
    const [productIdError, setproductIdError] = useState();
    const [result, setresult] = useState();
    const [errorResult, seterrorResult] = useState();
    const [inform, setinform] = useState();

    const [loaded, setLoaded] = useState(true);

    const isValid = ({ possibleErrors = [], requiredValues = [] }) =>
    possibleErrors.every((error) => error === false) &&
    requiredValues.every((value) => value !== '' && value !== undefined);
  
    const isReadyToSubmit = isValid({
      possibleErrors: [customerIdError, customerIdError],
      requiredValues: [customerId, productId],
    });


    const handleSubmit = async (e) => {
      seterrorResult(false);
      setinform(false);
      setresult(false);
      setLoaded(false);
      e.preventDefault();
        
      setcustomerId();
      setproductId();
      
      const init = {
        body: {
          customerId : customerId,
          productId : productId 
        }
      };
  
      const url = window.location.href;
      var endpoint = "";
      if (url.includes("localhost") || url.includes("preprod")) {
        endpoint = "https://xdnmv22ywd.execute-api.us-east-1.amazonaws.com/dev";
      }else {
        endpoint = "https://g2osn2wjql.execute-api.us-east-1.amazonaws.com/dev";
      }
  
      Amplify.configure({
        API: {
          endpoints: [
            {
              name: "ispui",
              endpoint: endpoint,
              custom_header: async () => { 
                return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
              }
            }
          ]
        }
      });


  
      console.log(`Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`);
      await API.post("ispui", "/echo", init).then(async (response) => {        
          if(JSON.stringify(response).replace('{"string":"','').replace('"}','').toString() == "Entitlements successfully reset!"){
            setresult("Entitlements successfully reset for CID: " + customerId + " and PID: " + productId); 
          } else if(JSON.stringify(response).replace('{"string":"','').replace('"}','').toString() =="Entitlements not found!") {
          setinform("Entitlements not found for CID: " + customerId + " and PID: " + productId ); 
          } else if (JSON.stringify(response).replace('{"string":"','').replace('"}','').toString() =="Please provide valid customer id!") {
            seterrorResult("Please provide valid customer id!"); 
          } else if (JSON.stringify(response).replace('{"string":"','').replace('"}','').toString() =="Customer ID cannot be empty!") {
            seterrorResult("Customer ID cannot be empty!"); 
          } else if (JSON.stringify(response).replace('{"string":"','').replace('"}','').toString() =="Please provide valid product id!") {
            seterrorResult("Please provide valid product id!"); 
          } else if (JSON.stringify(response).replace('{"string":"','').replace('"}','').toString() =="Product ID cannot be empty!") {
            seterrorResult("Product ID cannot be empty!"); 
          }
      })
      .catch((error) => {
        seterrorResult("Internal Error for CID: " + customerId + " and PID: " + productId)
      });
    
      setLoaded(true);      

    };

    const error = errorResult ? 
    <div><pre>{<Alert
      type="error"
      size="medium"
      onClose={()=>seterrorResult(false)}    
      >{errorResult}</Alert>}</pre></div> : <div></div>


    const sucess = result ? 
    
    <div> <pre>{<Alert
    type="success"
    size="medium"
    onClose={()=> setresult(false)}
  >{result}</Alert>}</pre></div> : <div></div>


  const informational = inform ?

  <div> <pre>{<Alert
    type="informational"
    size="medium"
    onClose={()=> setinform(false)}
  >{inform}</Alert>}</pre></div> : <div></div>
  

    return (

      
     <> 

     {

      !loaded ? <form >
                <Row alignmentHorizontal='center' spacingInset='large'>
                <Loader size="large"/>
                </Row> 
                </form> : 
      <form onSubmit={handleSubmit}>  


        <form>{error}</form>
        <form>{sucess}</form> 
        <form>{informational}</form>


        <div>
            <Row alignmentHorizontal='center' spacingInset='large'>
      <Heading level={2} type='d50'>
            Revoke ISP Purchase
          </Heading>
      </Row>
      <Column alignmentHorizontal='center'>
      <Row
              widths='grid-14'
              alignmentHorizontal='center'
              alignmentVertical='top'
            >
      <Column spacing="small">
      
      <Input
        label='Customer ID'
        value={customerId}
        onChange={(value) => setcustomerId(value.trim())}
        onBlur={() => setcustomerIdError(!customerId)}
        error={customerIdError}
      />
      {customerIdError && (
       <Alert type='error' size='small'>
         Customer ID Required
       </Alert>
      )}
    </Column>

      </Row>

      <Row
              widths='grid-14'
              alignmentVertical='top'
              alignmentHorizontal='center'
            >
      
    <Column spacing="small">
    <Tooltip position="right" title="For paid skills use skill id as product id">
      <Input
        label='Product ID'
        value={productId}
        onChange={(value) => setproductId(value.trim())}
        onBlur={() => setproductIdError(!productId)}
        error={productIdError}
      />
    </Tooltip>
      {productIdError && (
       <Alert type='error' size='small'>
         Product ID Required
       </Alert>
      )}
    </Column>
    </Row>
    

    <Row width='50%' alignmentHorizontal='center'>
            <Button size='medium' submit={true} disabled={!isReadyToSubmit}>
              Revoke Purchase
            </Button>
          </Row>

      </Column>

      
        </div>
        </form>
}
</>

      );
    };
    
    export default Components;